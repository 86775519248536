/** react-query query keys */
export enum ReactQueryKeys {
    ALERTS = "alerts",
    BILLING = "billing",
    GOOGLE = "google",
    ME = "me",
    PLANS = "plans",
    GOALS = "goals",
    USERS = "users",
    WEBSITES = "websites",
    WORKSPACES = "workspaces",
    EXPERIMENTS = "experiments",
}
