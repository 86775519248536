import {
    type MouseEventHandler,
    type PropsWithChildren,
    type ComponentType,
    createContext,
    type JSX,
    memo,
    useCallback,
    useMemo,
    useRef,
    useState,
    useEffect,
} from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { Transition, type TransitionStatus } from "react-transition-group";

import ExperimentsImage from "@/Assets/Images/Gating/experiments-min.webp";
import ProductRecosImage from "@/Assets/Images/Gating/product-recommendations-min.webp";
import WebPushImage from "@/Assets/Images/Gating/web-push-min.webp";

import {
    Content,
    ContentSection,
    Footer,
    ModalHeroImage,
} from "@/Support/Components/FeatureGating/FeatureGating.styled";
import { useSegment } from "@/Support/Hooks/useSegment";
import { useMe } from "@/Support/Requests/useMe";

import { FeatureGateEnum } from "@/__codegen__/graphql";

import { Button } from "@/Components/Button";
import { ButtonLink, Link } from "@/Components/Link";
import { Header, Modal } from "@/Components/Modal";

import Loading from "@/Pages/State/Loading";

import { FEATURE_ACTIVE } from "./FeatureGating.constants";

export type HandleGate = (
    feature: FeatureGateEnum,
    options: { closeCallback?: () => void; checkOnly?: boolean },
) => false | typeof FEATURE_ACTIVE;

export const FeatureGatingContext = createContext<HandleGate>(
    () => FEATURE_ACTIVE,
);

const Provider = ({
    value,
    children,
}: PropsWithChildren<{ readonly value: HandleGate }>) => {
    return (
        <FeatureGatingContext.Provider value={value}>
            {children}
        </FeatureGatingContext.Provider>
    );
};

type GateContent = ComponentType<{
    onClose: (withoutNavigation?: boolean) => void;
}>;

const gates: Record<string, GateContent> = {
    [FeatureGateEnum.WebPush]: memo(function WebPushGatingModal({ onClose }) {
        const intl = useIntl();

        const { track } = useSegment();

        const handleClick = useCallback(() => {
            track("gating.modal.click", {
                feature: FeatureGateEnum.WebPush,
            });
        }, [track]);

        const params = useParams<{
            workspace: string;
        }>();

        const workspaceId = +params.workspace;

        const handleClose = useCallback(() => {
            onClose();
        }, [onClose]);

        return (
            <>
                <ModalHeroImage src={WebPushImage} />
                <ContentSection>
                    <Header>
                        <span>
                            {intl.formatMessage({
                                id: "gating.modals.push.title",
                                description: "Web push gating modal title",
                                defaultMessage: "Need Web push?",
                            })}
                        </span>
                    </Header>
                    <Content>
                        <span>
                            {intl.formatMessage({
                                id: "gating.modals.push.content.one",
                                description:
                                    "Web push gating first part of content",
                                defaultMessage:
                                    "Start collecting subscribers now and drive repeat\n" +
                                    "traffic thanks to personalized web push campaigns.",
                            })}
                        </span>
                        <span>
                            {intl.formatMessage({
                                id: "gating.modals.push.content.two",
                                description:
                                    "Web push gating second part of content",
                                defaultMessage:
                                    "Upgrade to get access to Web push today. ",
                            })}
                            <Link
                                href="https://support.wisepops.com/en/articles/9898975-boost-engagement-with-push-notifications-beta"
                                kind="action"
                                target="_blank"
                            >
                                {intl.formatMessage({
                                    id: "gating.modals.push.content.learn_more",
                                    description:
                                        "Learn more link in web push gating description",
                                    defaultMessage: "Learn more",
                                })}
                            </Link>
                        </span>
                    </Content>
                    <Footer>
                        <Button
                            kind="ghost"
                            onClick={handleClose}
                            size="medium"
                        >
                            {intl.formatMessage({
                                id: "gating.modals.cta.cancel",
                                description: "Cancel button text",
                                defaultMessage: "Cancel",
                            })}
                        </Button>
                        <ButtonLink
                            kind="primary"
                            onClick={handleClick}
                            size="medium"
                            to={`/workspaces/${workspaceId}/billing/plan/setup`}
                        >
                            {intl.formatMessage({
                                id: "gating.modals.cta.upgrade",
                                description: "Upgrade button text",
                                defaultMessage: "Upgrade",
                            })}
                        </ButtonLink>
                    </Footer>
                </ContentSection>
            </>
        );
    }),
    [FeatureGateEnum.Experiments]: memo(function ExperimentsGatingModal({
        onClose,
    }) {
        const intl = useIntl();

        const { track } = useSegment();

        const params = useParams<{
            workspace: string;
        }>();

        const workspaceId = +params.workspace;

        const handleClick = useCallback<MouseEventHandler>(() => {
            track("gating.modal.click", {
                feature: FeatureGateEnum.Experiments,
            });
        }, [track]);

        const handleClose = useCallback(() => {
            onClose(true);
        }, [onClose]);

        return (
            <>
                <ModalHeroImage src={ExperimentsImage} />
                <ContentSection>
                    <Header>
                        <span>
                            {intl.formatMessage({
                                id: "gating.modals.experiments.title",
                                description: "Experiments gating modal title",
                                defaultMessage: "Need advanced experimenting?",
                            })}
                        </span>
                    </Header>
                    <Content>
                        <span>
                            {intl.formatMessage({
                                id: "gating.modals.experiments.content.one",
                                description:
                                    "Experiments gating first part of content",
                                defaultMessage:
                                    "Get the full picture on the incremental impact of Wisepops campaigns to optimize your visitors' experience and conversion.",
                            })}
                        </span>
                        <span>
                            {intl.formatMessage({
                                id: "gating.modals.experiments.content.two",
                                description:
                                    "Experiments gating second part of content",
                                defaultMessage:
                                    "This feature is part of our Intelligence plan, which also includes access to our powerful product recommendations (Shopify-only).",
                            })}{" "}
                            <Link
                                href="https://support.wisepops.com/en/articles/9901815-a-b-testing-optimize-campaigns-with-experiments"
                                kind="action"
                                target="_blank"
                            >
                                {intl.formatMessage({
                                    id: "gating.modals.experiments.content.learn_more",
                                    description:
                                        "Learn more link in Experiments gating description",
                                    defaultMessage: "Learn more",
                                })}
                            </Link>
                        </span>
                    </Content>
                    <Footer>
                        <Button
                            kind="ghost"
                            onClick={handleClose}
                            size="medium"
                        >
                            {intl.formatMessage({
                                id: "gating.modals.cta.cancel",
                                description: "Cancel button text",
                                defaultMessage: "Cancel",
                            })}
                        </Button>
                        <ButtonLink
                            kind="primary"
                            onClick={handleClick}
                            size="medium"
                            to={`/workspaces/${workspaceId}/billing/plan/setup`}
                        >
                            {intl.formatMessage({
                                id: "gating.modals.cta.go_to_pricing",
                                description:
                                    "Go to pricing (upgrade) button text",
                                defaultMessage: "Go to pricing",
                            })}
                        </ButtonLink>
                    </Footer>
                </ContentSection>
            </>
        );
    }),
    [FeatureGateEnum.ProductRecos]: memo(function ProductRecosGatingModal({
        onClose,
    }) {
        const intl = useIntl();

        const { track } = useSegment();

        const handleClick = useCallback(() => {
            track("gating.modal.click", {
                feature: FeatureGateEnum.ProductRecos,
            });

            window.parent.postMessage(
                { type: "GATING_REQUEST_ACCESS" },
                import.meta.env.VITE_POPUPS_WEBSITE_URL,
            );
        }, [track]);

        const handleClose = useCallback(() => {
            onClose(true);
        }, [onClose]);

        return (
            <>
                <ModalHeroImage src={ProductRecosImage} />
                <ContentSection>
                    <Header>
                        <span>
                            {intl.formatMessage({
                                id: "gating.modals.product-recos.title",
                                description:
                                    "Product recommendations gating modal title",
                                defaultMessage: "Unlock AI Recommendations",
                            })}
                        </span>
                    </Header>
                    <Content>
                        <span>
                            {intl.formatMessage({
                                id: "gating.modals.product-recos.content.one",
                                description:
                                    "Product recommendations gating first part of content",
                                defaultMessage:
                                    "Drive up to 5% more sales on autopilot with real-time personalized product recommendations for each visitor.",
                            })}
                        </span>
                        <span>
                            {intl.formatMessage({
                                id: "gating.modals.product-recos.content.two",
                                description:
                                    "Product recommendations gating second part of content",
                                defaultMessage:
                                    "This feature is part of our Intelligence plan, which also includes access to our powerful experimentation platform. Upgrade your plan to get access to Product recommendations.",
                            })}{" "}
                            <Link
                                href="https://support.wisepops.com/en/articles/9898987-ai-product-recommendations-guide"
                                kind="action"
                                target="_blank"
                            >
                                {intl.formatMessage({
                                    id: "gating.modals.experiments.content.learn_more",
                                    description:
                                        "Learn more link in Experiments gating description",
                                    defaultMessage: "Learn more",
                                })}
                            </Link>
                        </span>
                    </Content>
                    <Footer>
                        <Button
                            kind="ghost"
                            onClick={handleClose}
                            size="medium"
                        >
                            {intl.formatMessage({
                                id: "gating.modals.cta.cancel",
                                description: "Cancel button text",
                                defaultMessage: "Cancel",
                            })}
                        </Button>
                        <Button
                            kind="primary"
                            onClick={handleClick}
                            size="medium"
                        >
                            {intl.formatMessage({
                                id: "gating.modals.cta.go_to_pricing",
                                description:
                                    "Go to pricing (upgrade) button text",
                                defaultMessage: "Go to pricing",
                            })}
                        </Button>
                    </Footer>
                </ContentSection>
            </>
        );
    }),
};

const resetGate = () => ({
    in: false,
    Component: Loading as GateContent,
    name: "",
    display: false,
});

export const FeatureGating = ({ children }: PropsWithChildren): JSX.Element => {
    const { data, isFetching } = useMe();

    const { track } = useSegment();

    const restrictedFeatures = useMemo(() => {
        if (!data?.feature_gates) {
            return gates;
        }

        const featuresEnabled = data.feature_gates;

        return Object.entries(gates).reduce<typeof gates>(
            (restrictedFeatures, [feature, value]) => {
                if (!featuresEnabled.includes(feature)) {
                    restrictedFeatures[feature] = value;
                }
                return restrictedFeatures;
            },
            {},
        );
    }, [data]);

    const modalRef = useRef<HTMLDivElement>(null);

    const [gate, setGate] = useState<{
        in: boolean;
        display: boolean;
        Component: GateContent;
        name: string;
        closeCallback?: () => void;
    }>(resetGate);

    const handleClose = useCallback(() => {
        const callback = gate.closeCallback;

        // 2-step closing for exit animation
        setGate((currentGate) => ({
            ...currentGate,
            in: false,
        }));

        setTimeout(() => {
            setGate((currentGate) => ({
                ...currentGate,
                closeCallback: undefined,
                display: false,
                Component: Loading as GateContent,
            }));

            callback?.();
        }, 400);
    }, [gate]);

    useEffect(() => {
        // reset gate when url changes (back button, etc.)
        setGate(resetGate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [document.location.pathname]);

    const handleGate = useCallback(
        (
            feature: string,
            {
                closeCallback,
                checkOnly,
            }: { closeCallback?: () => void; checkOnly?: boolean },
        ) => {
            const gate = restrictedFeatures[feature];

            if (!gate) {
                return FEATURE_ACTIVE;
            }

            if (!checkOnly) {
                track("gating.modal.display", {
                    feature,
                });

                setGate({
                    in: true,
                    display: true,
                    Component: gate,
                    name: feature,
                    closeCallback,
                });
            }

            return false;
        },
        [restrictedFeatures, track],
    );

    if (isFetching) {
        return <Loading />;
    }

    const GateComponent = gate.Component;

    return (
        <Provider value={handleGate}>
            <Transition in={gate.in} nodeRef={modalRef} timeout={500}>
                {(state: TransitionStatus): JSX.Element | null => {
                    if (!gate.display) {
                        return null;
                    }

                    return (
                        <Modal
                            key={gate.name}
                            padding={0}
                            parent="app"
                            ref={modalRef}
                            transitionStatus={state}
                            width={599}
                        >
                            <GateComponent onClose={handleClose} />
                        </Modal>
                    );
                }}
            </Transition>
            {children}
        </Provider>
    );
};
