import { useCallback, useMemo } from "react";

import { useMe } from "@/Support/Requests/useMe";

import { type FeatureGateEnum, type MeQuery } from "@/__codegen__/graphql";

interface Track {
    (event: "page", properties: { url: string; name: string }): void;
    (event: "users.pagination.next", properties: { page: string }): void;
    (event: "users.user.delete.confirm", properties: { user_id: number }): void;
    (
        event: "users.invite.click" | "websites.add_website.click",
        properties: { variant: "bottom button" | "top button" },
    ): void;
    (event: "setup.next.click", properties: { medium: string }): void;
    (
        event: "popups.gallery.category.select",
        properties: { category: string },
    ): void;
    (
        event: "popups.gallery.template.select",
        properties: {
            template_id: number;
            template_type: string;
            category: string | null;
        },
    ): void;
    (
        event: "gating.modal.display" | "gating.modal.click",
        properties: {
            feature: FeatureGateEnum | string;
        },
    ): void;
    (
        event:
            | "account.name.edit.save"
            | "account.name.edit.start"
            | "account.picture.add.click"
            | "popups.gallery.open"
            | "setup.survey.finished"
            | "setup.survey.started"
            | "setup.survey.step1"
            | "setup.survey.step2"
            | "setup.survey.step3"
            | "setup.survey.step4"
            | "setup.survey.step5"
            | "setup.survey.step6"
            | "setup.survey.step7"
            | "users.user.deselect"
            | "users.user.edit_access.click"
            | "users.user.select"
            | "websites.website.actions.click"
            | "websites.website.activate.click"
            | "websites.website.activate.confirm"
            | "websites.website.dashboard.click"
            | "websites.website.deactivate.click"
            | "websites.website.deactivate.confirm"
            | "websites.website.delete.click"
            | "websites.website.delete.confirm"
            | "websites.website.edit_access.click"
            | "websites.website.edit_access.confirm"
            | "websites.website.edit_url.click"
            | "websites.website.edit_url.confirm"
            | "websites.website.invite_editors"
            | "websites.website.setup_code.click"
            | "websites.website.setup_code.copy"
            | "websites.website.setup_code.help.click"
            | "billing.plan.edit.intelligence.contact_request"
            | "billing.plan.upgrade.to_essential.custom_pageviews"
            | "billing.plan.upgrade.to_intelligence.request",
        properties?: never,
    ): void;
}

type Page = (title: string) => void;

type Identify = (data: MeQuery["me"]) => void;

type UseSegmentReturn = {
    invoke: () => void;
    track: Track;
    page: Page;
    identify: Identify;
    reset: () => void;
};

/** Bounces Segment calls in order to wait for it to be initialized */
const execute = (code: () => void): void => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!window.analytics?.initialized) {
        setTimeout(() => {
            execute(code);
        }, 50);
        return;
    }
    code();
};

/** Segment hook */
export const useSegment = (): UseSegmentReturn => {
    const { isFetching, isError, data: me } = useMe();

    const impersonationStatus = useMemo(() => {
        if (isFetching) {
            return "pending";
        }

        if (isError) {
            return "unauthenticated";
        }

        if (me?.is_impersonating === true) {
            return "impersonating";
        }

        return "self";
    }, [me?.is_impersonating, isError, isFetching]);

    const invoke = useCallback(() => {
        window.invokeSegment();
    }, []);

    const reset = useCallback(() => {
        execute(() => {
            window.analytics.reset();
            // Intercom presence does not depend on the front, therefore it might not always be injected in HELP button
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (Intercom) {
                Intercom("shutdown");
                if (impersonationStatus === "self") {
                    if (import.meta.env.VITE_INTERCOM_APP_ID) {
                        Intercom("boot", {
                            app_id: import.meta.env.VITE_INTERCOM_APP_ID,
                        });
                    } else {
                        console.error(
                            "Cannot reboot Intercom after analytics reset: help widget is present but VITE_INTERCOM_APP_ID is not defined.",
                        );
                    }
                }
            }
        });
    }, [impersonationStatus]);

    const track = useCallback<Track>(
        (...args) => {
            if (impersonationStatus !== "self") return;
            execute(() => {
                window.analytics.track(...args);
            });
        },
        [impersonationStatus],
    );

    const page = useCallback<Page>(
        (title) => {
            if (impersonationStatus !== "self") return;
            execute(() => {
                window.analytics.page({
                    category: "app",
                    name: window.location.pathname,
                    title,
                });
            });
        },
        [impersonationStatus],
    );

    const identify = useCallback<Identify>(
        (data) => {
            if (impersonationStatus !== "self") return;
            execute(() => {
                window.analytics.identify(
                    `user_${data.id}`,
                    {
                        username: data.email,
                        name: data.name,
                        email: data.email,
                        role: data.workspaces.items[0]?.role,
                        createdAt: data.created_at,
                        company: {
                            id: data.workspaces.items[0]?.workspace.id,
                            name: data.workspaces.items[0]?.workspace
                                .company_name,
                        },
                    } as unknown as object,
                    {
                        Intercom: {
                            user_hash: data.intercom_user_hash,
                        },
                    } as unknown as object,
                );
            });
        },
        [impersonationStatus],
    );

    return useMemo(
        () => ({ track, page, identify, reset, invoke }),
        [track, page, identify, reset, invoke],
    );
};
