import { graphql } from "@/__codegen__";

import { type UseQueryOptions, useQuery } from "@/Support/Hooks/useQuery";

import { type MeQuery, namedOperations } from "@/__codegen__/graphql";

import { graphQLClient } from "./graphQLClient";
import { ReactQueryKeys } from "./reactQueryKeys";

/** Initial request made to API to check if user is logged in and to get user and workspace information */
export const useMe = (options?: UseQueryOptions<MeQuery["me"]>) =>
    useQuery({
        queryKey: [ReactQueryKeys.ME, namedOperations.Query.Me],
        queryFn: async ({ signal }) => {
            const { me } = await graphQLClient.request({
                document: graphql(`
                    query Me {
                        me {
                            id
                            name
                            email
                            last_active_at
                            created_at
                            picture: picture_url
                            unverifiedEmail: unverified_email
                            intercom_user_hash
                            is_impersonating
                            nova_route
                            is_nova_admin: is_admin
                            telescope_route
                            stop_impersonate_route
                            popups_route
                            popups_impersonator_route
                            notification_impersonator_route
                            feature_gates
                            workspaces: userWorkspace(pagination: {}) {
                                items {
                                    workspace {
                                        id
                                        created_at
                                        company_name: metadataCompanyName
                                        releases
                                        website_plugins
                                    }
                                    role
                                    is_setup: metadataIsSetup
                                }
                            }
                        }
                    }
                `),
                signal,
            });

            return me;
        },
        ...options,
        /* Never retry on mount the useMe query hook. It is what defines if a user is logged in or not */
        retryOnMount: false,
    });
