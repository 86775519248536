import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FeatureGatingContext } from "@/Support/Components/FeatureGating/FeatureGating";

import { type FeatureGateEnum } from "@/__codegen__/graphql";

export const useFeatureGating = (
    feature: FeatureGateEnum,
    options: { closeCallback?: () => void; checkOnly?: boolean } = {},
): [boolean, (e?: Pick<Event, "preventDefault">) => boolean] => {
    const checkGateOpen = useContext(FeatureGatingContext);

    const [gateOpen, setGateOpen] = useState<boolean>(false);

    const triggerGateOpen = useCallback(
        (e?: Pick<Event, "preventDefault">, checkOnly?: boolean) => {
            const isGateOpen = checkGateOpen(feature, {
                closeCallback: options.closeCallback,
                checkOnly,
            });

            if (!isGateOpen) {
                e?.preventDefault();
            }

            setGateOpen(isGateOpen);

            return isGateOpen;
        },
        [checkGateOpen, feature, options.closeCallback],
    );

    useEffect(() => {
        triggerGateOpen(undefined, options.checkOnly);
    }, [options.checkOnly, triggerGateOpen]);

    return [gateOpen, triggerGateOpen];
};

const useFeatureGatingwithNavigate = (
    feature: FeatureGateEnum,
    options: { checkOnly?: boolean } = {},
) => {
    const routerNavigate = useNavigate();

    const featureGatingOptions = useMemo(() => {
        return {
            closeCallback: () => {
                routerNavigate(-1);
            },
            checkOnly: options.checkOnly,
        };
    }, [options.checkOnly, routerNavigate]);

    return useFeatureGating(feature, featureGatingOptions);
};

useFeatureGating.withNavigate = useFeatureGatingwithNavigate;
