import styled from "styled-components";

import { Badge } from "@/Components/Badge";
import LockIcon from "@/Components/Icons/LockIcon.svg?react";
import {
    Content as ModalContent,
    Footer as ModalFooter,
} from "@/Components/Modal";

export const Footer = styled(ModalFooter)`
    margin-top: var(--space-lg);
`;

export const Content = styled(ModalContent)`
    gap: var(--space-lg);
`;

export const ModalHeroImage = styled.img`
    border-radius: var(--space-lg) var(--space-lg) 0 0;
`;

export const ContentSection = styled.section`
    display: flex;
    flex-direction: column;
    gap: var(--space-xl);
    padding: 0 32px 32px;
`;

export const LockBadge = styled(Badge).attrs({
    color: "yellow",
    kind: "light",
    shape: "round",
    size: "small",
    children: (
        <LockIcon color="var(--color-warning-700)" height={12} width={12} />
    ),
})`
    background-color: var(--color-yellow-200);
    padding: 6px;
`;
