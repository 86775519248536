import { gql } from "graphql-request";

import { type UseQueryOptions, useQuery } from "@/Support/Hooks/useQuery";

import { type BillingInterval } from "@/Pages/Billing/types";
import { type Currency } from "@/Pages/types";

import { graphQLClient, GraphQLQuery } from "./graphQLClient";
import { ReactQueryKeys } from "./reactQueryKeys";

export interface BillingPayload {
    workspace: number;
}

export interface BillingResponse {
    chargeUserCreation: boolean;
    chargeWebsiteActivation: boolean;
    chargeWebsiteDeactivation: boolean;
    userCreationPrice: number;
    websiteActivationPrice: number;
    currency: Currency | null;
    billingInterval: BillingInterval | null;
    planPrice: number;
    planName: string;
    expiresAt: string;
}

export interface BillingGraphQLResponse {
    billingInformation: BillingResponse;
}

export type BillingGraphQLVariables = {
    workspaceId: number;
};

/** Gets billing information from the current workspace. */
export const useBilling = (
    { workspace }: BillingPayload,
    options?: UseQueryOptions<BillingResponse>,
) =>
    useQuery<BillingResponse>({
        queryKey: [
            ReactQueryKeys.BILLING,
            GraphQLQuery.BillingQuery,
            { workspace },
        ],
        queryFn: async ({ signal }) => {
            const { billingInformation } = await graphQLClient.request<
                BillingGraphQLResponse,
                BillingGraphQLVariables
            >({
                document: gql`query ${GraphQLQuery.BillingQuery}($workspaceId: Int!) {
                        billingInformation(workspaceId: $workspaceId) {
                            chargeUserCreation
                            userCreationPrice
                            chargeWebsiteActivation
                            chargeWebsiteDeactivation
                            websiteActivationPrice
                            billingInterval
                            currency
                            planName
                            planPrice
                            expiresAt
                        }
                    }`,
                variables: {
                    workspaceId: workspace,
                },
                signal,
            });

            return billingInformation;
        },
        ...options,
    });
