import { useRef, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { getDaysBetween } from "@/Support/date";
import { useCurrentWorkspace } from "@/Support/Hooks/useCurrentWorkspace";
import { useSegment } from "@/Support/Hooks/useSegment";
import { useBilling } from "@/Support/Requests/useBilling";
import { useEditorBilling } from "@/Support/Requests/useEditorBilling";
import { useMe } from "@/Support/Requests/useMe";

import { UserRoleEnum } from "@/__codegen__/graphql";

export const useWisepops = (): void => {
    const location = useLocation();

    const segment = useSegment();

    const [searchParams, setSearchParams] = useSearchParams();

    const wisepopsInitRef = useRef(false);

    const currentWorkspace = useCurrentWorkspace();

    const isEditor = currentWorkspace?.role === UserRoleEnum.Editor;

    const { isSuccess: isEditorBillingSuccess, data: editorBillingData } =
        useEditorBilling(
            { workspaceId: currentWorkspace?.workspace.id ?? 0 },
            {
                enabled:
                    typeof currentWorkspace?.workspace.id === "number" &&
                    isEditor,
            },
        );

    const { isSuccess: isAdminBillingSuccess, data: adminBillingData } =
        useBilling(
            { workspace: currentWorkspace?.workspace.id ?? 0 },
            {
                enabled:
                    typeof currentWorkspace?.workspace.id === "number" &&
                    !isEditor,
            },
        );

    const {
        isError: isMeError,
        isSuccess: isMeSuccess,
        data: meData,
    } = useMe();

    useEffect(
        () => {
            if (isMeError) {
                if (!wisepopsInitRef.current) {
                    wisepopsInitRef.current = true;
                }
                if (
                    searchParams.get("after_logout") === "true" &&
                    location.pathname === "/login"
                ) {
                    segment.reset();
                    setSearchParams(
                        (prev) => {
                            prev.delete("after_logout");
                            return prev;
                        },
                        { replace: true },
                    );
                }
                return;
            }
            if (wisepopsInitRef.current) return;
            if (
                isMeSuccess &&
                ((!isEditor && isAdminBillingSuccess) ||
                    (isEditor && isEditorBillingSuccess))
            ) {
                const [item] = meData.workspaces.items; // TODO: later improve when handling multiple workspaces

                if (item === undefined) {
                    console.error(
                        new Error(
                            "Cannot initiate Wisp and Wisepops properties as there is no available workspace",
                        ),
                    );
                    return;
                }

                const remainingDays = isAdminBillingSuccess
                    ? Math.max(
                          getDaysBetween(
                              new Date(),
                              new Date(adminBillingData.expiresAt),
                          ),
                          0,
                      )
                    : isEditorBillingSuccess
                      ? Math.max(
                            getDaysBetween(
                                new Date(),
                                new Date(editorBillingData.expires_at),
                            ),
                            0,
                        )
                      : undefined;

                wisepopsInitRef.current = true;
                segment.identify(meData);
                window.wisepops?.(
                    "properties",
                    {
                        user: {
                            w2Id: meData.id,
                            firstName: meData.name,
                            role: item.role,
                            creationDate: meData.created_at,
                            email: meData.email,
                        },
                        company: {
                            w2Id: item.workspace.id,
                            name: item.workspace.company_name,
                            accountEndDate:
                                adminBillingData?.expiresAt ??
                                editorBillingData?.expires_at,
                            remainingDays,
                            creationDate: item.workspace.created_at,
                            websitesPlugins:
                                item.workspace.website_plugins.join(","),
                            planPriceMonth:
                                adminBillingData?.planPrice ??
                                editorBillingData?.plan_price_month,
                            planName:
                                adminBillingData?.planName ??
                                editorBillingData?.plan_name,
                            canaryReleases: item.workspace.releases.join(","),
                            featureGates: meData.feature_gates.join(","),
                        },
                    },
                    { temporary: true },
                );
            }
        },
        /* eslint-disable-line react-hooks/exhaustive-deps */ [
            isEditorBillingSuccess,
            isAdminBillingSuccess,
            isMeSuccess,
            isMeError,
        ],
    );
};
