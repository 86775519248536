import { lazy, Suspense, useContext, useLayoutEffect, type JSX } from "react";
import { useIntl } from "react-intl";
import { Route, useParams, useMatch } from "react-router-dom";

import { useWisepops } from "@/Support/Hooks/useWisepops";
import { redirectDashboard } from "@/Support/Requests/Rest/redirectDashboard";
import { redirectPostSetup } from "@/Support/Requests/Rest/redirectPostSetup";
import { useMe } from "@/Support/Requests/useMe";

import { UserRoleEnum } from "@/__codegen__/graphql";

import { HelpTrigger } from "@/Components/HelpTrigger";
import { Navigate } from "@/Components/Navigate";
import { ProtectedRoute } from "@/Components/ProtectedRoute";
import { RegisteredRoute } from "@/Components/RegisteredRoute";
import { Routes } from "@/Components/Routes";

import { Consent } from "@/Pages/Consent";
import Context from "@/Pages/Context";
import Page from "@/Pages/Layouts/Page";
import Loading from "@/Pages/State/Loading";
import Maintenance from "@/Pages/State/Maintenance";
import ScriptErrorBoundary from "@/Pages/State/ScriptErrorBoundary";

const TemplateGallery = lazy(async () => import("@/Pages/TemplateGallery"));

const Nav = lazy(async () => import("@/Pages/Layouts/Nav"));

const NotFound = lazy(async () => import("@/Pages/State/NotFound"));
const ServerError = lazy(async () => import("@/Pages/State/ServerError"));
const Forbidden = lazy(async () => import("@/Pages/State/Forbidden"));
const Redirect = lazy(async () => import("@/Pages/State/Redirect"));

const Account = lazy(async () => import("@/Pages/Account"));

const Billing = lazy(async () => import("@/Pages/Billing"));
const PlanSetup = lazy(async () => import("@/Pages/PlanSetup"));
const PlanConfirmation = lazy(async () => import("@/Pages/PlanConfirmation"));
const Users = lazy(async () => import("@/Pages/Users"));
const NotificationsSetup = lazy(
    async () => import("@/Pages/NotificationsSetup"),
);
const Websites = lazy(async () => import("@/Pages/Websites"));
const SetupCode = lazy(async () => import("@/Pages/SetupCode"));
const GoalSettings = lazy(async () => import("@/Pages/Settings/Pages/Goals"));
const HomePage = lazy(async () => import("@/Pages/Home"));
const WebPushesSettings = lazy(
    async () => import("@/Pages/Settings/Pages/WebPushes"),
);

const AnalyticsOverviewPage = lazy(async () => import("@/Pages/Analytics"));
const AnalyticsPushPage = lazy(
    async () => import("@/Pages/Analytics/PushPage"),
);

const Goals = lazy(async () => import("@/Pages/Goals"));
const Goal = lazy(async () => import("@/Pages/Goals/Components/Goal"));

const Experiments = lazy(async () => import("@/Pages/Experiments"));
const Experiment = lazy(async () => import("@/Pages/Experiments/Experiment"));

const AccountSetupSurvey = lazy(
    async () => import("@/Pages/AccountSetupSurvey"),
);

const Authentication = lazy(async () => import("@/Pages/Authentication"));

const Settings = lazy(async () => import("@/Pages/Settings"));

const NavBilling = lazy(async () => import("./Layouts/Components/NavBilling"));

const SelectWebsite = lazy(async () => import("@/Pages/SelectWebsite"));

const EmbeddedEditGoal = (): JSX.Element => {
    const params = useParams<{ id: string }>();

    return <Goal edit={+params.id} embed />;
};

const statePages = (
    <>
        <Route
            element={
                <Page title="Server error">
                    <ServerError />
                </Page>
            }
            path="500"
        />
        <Route
            element={
                <Page title="Forbidden">
                    <Forbidden />
                </Page>
            }
            path="403"
        />
        <Route
            element={
                <Page title="Not found">
                    <NotFound />
                </Page>
            }
            path="*"
        />
    </>
);

const isAPIMocked = import.meta.env.VITE_API_MOCK_ENABLED === "true";

const App = (): JSX.Element => {
    const { maintenanceMode } = useContext(Context);

    const intl = useIntl();

    const { data, isSuccess: isLoggedIn, error, isPending, isError } = useMe();

    const isEditor = data?.workspaces.items[0]?.role === UserRoleEnum.Editor;

    const isImpersonating = data?.is_impersonating === true;

    const isAlreadySetup = data?.workspaces.items[0]?.is_setup === true;

    const isEmbed = useMatch("/embed/*");

    const isEmbeddingPageviews = useMatch("/embed/pageviews");

    const isEmbeddingTemplateGallery = useMatch("/embed/template-gallery");

    useLayoutEffect(() => {
        if (!isEmbeddingPageviews) return;
        document.body.style.background = "var(--color-neutral-50)";
    }, [isEmbeddingPageviews]);

    useWisepops();

    return (
        <ScriptErrorBoundary>
            <Suspense
                fallback={
                    isEmbeddingPageviews ? null : (
                        <Loading
                            title={intl.formatMessage({
                                id: "app.loading.page",
                                defaultMessage: "Loading page...",
                                description:
                                    "App loader title when loading js bundle",
                            })}
                        />
                    )
                }
            >
                {maintenanceMode || error?.code === 517 ? (
                    isEmbeddingPageviews ? null : (
                        <Maintenance />
                    )
                ) : isPending ? (
                    isEmbeddingPageviews ? null : (
                        <Loading
                            title={intl.formatMessage({
                                id: "app.loading.user",
                                defaultMessage: "Loading profile...",
                                description:
                                    "App loader title when loading user profile",
                            })}
                        />
                    )
                ) : (
                    <Routes>
                        <Route
                            element={
                                isLoggedIn && !isAPIMocked ? (
                                    <Redirect href={redirectDashboard()} />
                                ) : (
                                    <Authentication type="login" />
                                )
                            }
                            path="login"
                        />
                        <Route
                            element={
                                isLoggedIn && !isAPIMocked ? (
                                    <Redirect href={redirectDashboard()} />
                                ) : (
                                    <Authentication type="register" />
                                )
                            }
                            path="signup"
                        />
                        <Route element={<Redirect />} path="redirect" />
                        <Route
                            element={
                                <ProtectedRoute>
                                    {isAlreadySetup ? (
                                        <Redirect href={redirectPostSetup()} />
                                    ) : (
                                        <AccountSetupSurvey />
                                    )}
                                </ProtectedRoute>
                            }
                            path="account/setup/*"
                        />
                        <Route
                            element={
                                <ProtectedRoute>
                                    <RegisteredRoute>
                                        <SelectWebsite />
                                    </RegisteredRoute>
                                </ProtectedRoute>
                            }
                            path="workspaces/:workspace/websites/select/*"
                        />
                        <Route path="workspaces/:workspace/billing/plan/*">
                            <Route
                                element={
                                    <ProtectedRoute notGated>
                                        <RegisteredRoute>
                                            <PlanSetup />
                                        </RegisteredRoute>
                                    </ProtectedRoute>
                                }
                                path="discount/:discountCode/setup"
                            />
                            <Route
                                element={
                                    <ProtectedRoute notGated>
                                        <RegisteredRoute>
                                            <PlanSetup />
                                        </RegisteredRoute>
                                    </ProtectedRoute>
                                }
                                path="setup"
                            />
                            <Route path=":planId/interval/:billingInterval">
                                <Route
                                    element={
                                        <ProtectedRoute notGated>
                                            <RegisteredRoute>
                                                <PlanConfirmation />
                                            </RegisteredRoute>
                                        </ProtectedRoute>
                                    }
                                    path="discount/:discountCode/confirm"
                                />
                                <Route
                                    element={
                                        <ProtectedRoute notGated>
                                            <RegisteredRoute>
                                                <PlanConfirmation />
                                            </RegisteredRoute>
                                        </ProtectedRoute>
                                    }
                                    path="key/:individualPlanKey/confirm"
                                />
                                <Route
                                    element={
                                        <ProtectedRoute notGated>
                                            <RegisteredRoute>
                                                <PlanConfirmation />
                                            </RegisteredRoute>
                                        </ProtectedRoute>
                                    }
                                    path="key/:individualPlanKey/discount/:discountCode/confirm"
                                />
                                <Route
                                    element={
                                        <ProtectedRoute notGated>
                                            <RegisteredRoute>
                                                <PlanConfirmation />
                                            </RegisteredRoute>
                                        </ProtectedRoute>
                                    }
                                    path="confirm"
                                />
                            </Route>
                        </Route>
                        <Route path="/embed">
                            <Route
                                element={<TemplateGallery />}
                                path="template-gallery"
                            />
                            <Route
                                element={<NavBilling embed />}
                                path="pageviews"
                            />
                            <Route
                                element={<GoalSettings embed />}
                                path="workspaces/:workspace/settings/goals"
                            />
                            <Route
                                element={<Goal embed />}
                                path="workspaces/:workspace/goals/add"
                            />
                            <Route
                                element={<EmbeddedEditGoal />}
                                path="workspaces/:workspace/goals/:id/edit"
                            />
                        </Route>
                        <Route
                            element={
                                <ProtectedRoute>
                                    <RegisteredRoute>
                                        <Nav />
                                    </RegisteredRoute>
                                </ProtectedRoute>
                            }
                            path="/"
                        >
                            <Route element={<Navigate to="account" />} index />

                            <Route path="workspaces/:workspace/analytics">
                                <Route
                                    element={<AnalyticsOverviewPage />}
                                    index
                                />
                                <Route
                                    element={<AnalyticsPushPage />}
                                    path="web-push"
                                />
                            </Route>
                            <Route
                                element={<Goals />}
                                path="workspaces/:workspace/goals/*"
                            />
                            <Route path="workspaces/:workspace/experiments">
                                <Route element={<Experiments />} index />
                                <Route element={<Experiment />} path=":id" />
                            </Route>
                            <Route
                                element={<Settings />}
                                path="workspaces/:workspace/settings"
                            >
                                <Route
                                    element={
                                        isEditor ? (
                                            <Navigate to="/403" />
                                        ) : (
                                            <Users />
                                        )
                                    }
                                    path="users/*"
                                />
                                <Route
                                    element={<Websites />}
                                    path="websites/*"
                                />
                                <Route
                                    element={
                                        <Navigate
                                            replace
                                            to="/workspaces/:workspace/billing/plan/:url/*"
                                        />
                                    }
                                    path="billing/plan/:url/*"
                                />
                                <Route
                                    element={
                                        isEditor ? (
                                            <Navigate to="/403" />
                                        ) : (
                                            <Billing />
                                        )
                                    }
                                    path="billing/*"
                                />
                                <Route
                                    element={<SetupCode />}
                                    path="setup-code/*"
                                />
                                <Route
                                    element={<NotificationsSetup />}
                                    path="feed/*"
                                />
                                <Route
                                    element={
                                        isEditor ? (
                                            <Navigate to="/403" />
                                        ) : (
                                            <GoalSettings />
                                        )
                                    }
                                    path="goals"
                                />
                                <Route
                                    element={<WebPushesSettings />}
                                    path="web-pushes"
                                />
                            </Route>
                            <Route element={<Account />} path="account" />
                            <Route
                                element={<HomePage />}
                                path="workspaces/:workspace/home"
                            />
                            {isLoggedIn ? statePages : null}
                        </Route>
                        {!isLoggedIn && statePages}
                    </Routes>
                )}
            </Suspense>
            {import.meta.env.VITE_SEGMENT_ANALYTICS_ENABLED === "true" &&
            !isImpersonating &&
            (isError || !isPending) &&
            (isEmbeddingTemplateGallery || !isEmbed) ? (
                <Consent />
            ) : null}
            {!isEmbed && <HelpTrigger />}
        </ScriptErrorBoundary>
    );
};

export default App;
