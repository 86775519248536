/* eslint-disable react/no-unsafe */

import {
    type PointerEvent,
    type ErrorInfo,
    type HTMLAttributes,
    type ReactNode,
    type ForwardedRef,
    type KeyboardEvent,
    type JSX,
    forwardRef,
    PureComponent,
    useCallback,
    useRef,
} from "react";
import { createPortal } from "react-dom";
import { type IntlShape, injectIntl } from "react-intl";
import { type TransitionStatus, Transition } from "react-transition-group";
import styled, {
    type FlattenSimpleInterpolation,
    css,
    createGlobalStyle,
    keyframes,
} from "styled-components";

import TimesIcon from "@/Components/Icons/TimesIcon.svg?react";

import { Button } from "./Button";
import { Text } from "./Text";

const TopClosure = styled(Button).attrs({
    size: "small",
    kind: "ghost",
    icon: <TimesIcon height="16" width="16" />,
})`
    position: absolute;
    right: 8px;
    top: 8px;
`;

const ErrorTitle = styled(Text.H1)`
    font-weight: 500;
    text-align: center;
`;

const ErrorContent = styled(Text.Body)`
    color: var(--color-neutral-700);
    text-align: center;
`;

const ErrorFooter = styled.div`
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;

export const Error = styled(
    ({
        title,
        footer,
        children,
        ...otherProps
    }: HTMLAttributes<HTMLDivElement> & {
        title?: ReactNode;
        footer: ReactNode;
    }) => (
        <div {...otherProps}>
            {title ? <ErrorTitle>{title}</ErrorTitle> : null}
            <ErrorContent>{children}</ErrorContent>
            <ErrorFooter>{footer}</ErrorFooter>
        </div>
    ),
)`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 28px 0;
`;

const opacitySkeleton = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0.5;
    }
`;

const Skeleton = styled.div<{
    $width: number;
}>`
    animation: ${opacitySkeleton} 0.6s cubic-bezier(0.5, 0, 0.2, 1) alternate
        infinite;
    background-color: var(--color-neutral-200);
    border-radius: 4px;
    height: 8px;
    width: ${({ $width }): number => $width}px;
`;

export const Loader = styled((props: HTMLAttributes<HTMLDivElement>) => (
    <div {...props}>
        <Skeleton $width={160} />
        <Skeleton $width={350} />
        <Skeleton $width={380} />
        <Skeleton $width={200} />
    </div>
))`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`;

export const Header = styled(Text.H1)`
    align-items: center;
    display: flex;
    font-weight: 500;
    gap: 6px;
`;

Header.displayName = "Modal.Header";

export const Footer = styled.div`
    align-items: center;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
`;

Footer.displayName = "Modal.Footer";

export const Content = styled(Text.BodyL)`
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
`;

Content.displayName = "Modal.Content";

const DialogBase = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ children, ...props }, ref): JSX.Element => {
        const handlePointerDown = useCallback((event: PointerEvent) => {
            event.stopPropagation();
        }, []);

        return (
            <div
                aria-modal
                ref={ref}
                role="dialog"
                {...props}
                onPointerDown={handlePointerDown}
            >
                {children}
            </div>
        );
    },
);

DialogBase.displayName = "DialogBase";

const StyledDialog = styled(DialogBase)<{
    $width: number;
    $padding?: string | number;
}>`
    background-color: var(--color-background);
    border-radius: var(--space-lg);
    box-shadow: var(--shadow-level-5);
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: auto;
    padding: ${({ $padding }) => $padding ?? "32px"};
    position: relative;
    width: ${({ $width }): string => ($width ? `${$width}px` : "fit-content")};
`;

const fade = keyframes`
    from {
        background: rgba(7, 21, 44, 0);
    }
    to {
        background: rgba(7, 21, 44, 0.25);
    }
`;

const translateY = keyframes`
    from {
        transform: translateY(25%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const StyledBackground = styled.div<{
    $transitionStatus?: TransitionStatus;
}>`
    --fade-duration: 0.2s;
    --translate-duration: 0.3s;

    background: rgb(7 21 44 / 25%);
    display: flex;
    height: 100%;
    left: 0;
    overflow: auto;
    padding: 12px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: var(--z-index-modal);
    ${({ $transitionStatus }): FlattenSimpleInterpolation | false =>
        $transitionStatus === "entering" &&
        css`
            animation: ${fade} var(--fade-duration) linear forwards;
            overflow: hidden;
            & ${StyledDialog} {
                animation: ${translateY} var(--translate-duration) ease-out
                    forwards;
                animation-delay: var(--fade-duration);
                opacity: 0;
            }
        `}
    ${({ $transitionStatus }): FlattenSimpleInterpolation | false =>
        $transitionStatus === "exiting" &&
        css`
            animation: ${fade} var(--fade-duration) linear reverse forwards;
            animation-delay: var(--translate-duration);
            overflow: hidden;
            & ${StyledDialog} {
                animation: ${translateY} var(--translate-duration) ease-out
                    reverse forwards;
            }
        `}
`;

const Background = ({
    visible,
    ...otherProps
}: HTMLAttributes<HTMLDivElement> & {
    readonly visible?: boolean;
}): JSX.Element => {
    const ref = useRef<HTMLDivElement>(null);

    return (
        <Transition
            in={visible}
            mountOnEnter
            nodeRef={ref}
            timeout={500}
            unmountOnExit
        >
            {(state): JSX.Element => (
                <StyledBackground
                    {...otherProps}
                    $transitionStatus={state}
                    ref={ref}
                />
            )}
        </Transition>
    );
};

Background.displayName = "Modal.Background";

const HiddenBodyOverflow = createGlobalStyle`
    body {
        overflow: hidden;
    }
`;

type ModalState = { hasError: boolean };

type ModalProps = HTMLAttributes<HTMLDivElement> & {
    readonly parent?: HTMLElement | string | null;
    readonly width: number;
    readonly transitionStatus?: TransitionStatus;
    readonly onClose?: () => void;
    readonly intl: IntlShape;
    readonly enableTopClosure?: boolean;
    readonly innerRef?: ForwardedRef<HTMLDivElement>;
    readonly padding?: string | number;
};

class ModalErrorBoundary extends PureComponent<ModalProps, ModalState> {
    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility, react/sort-comp
    constructor(props: ModalProps) {
        super(props);
        this.state = { hasError: false };
        this.handleRetry = this.handleRetry.bind(this);
        this.handlePointerDown = this.handlePointerDown.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    public static getDerivedStateFromError(): ModalState {
        return { hasError: true };
    }

    public static UNSAFE_componentWillMount(): void {
        (document.activeElement as HTMLElement | null)?.blur();
    }

    public static componentDidCatch(error: Error, info: ErrorInfo): void {
        console.error("Uncaught error:", error, info);
    }

    public componentDidMount(): void {
        document.addEventListener("keydown", this.handleKeyDown);
    }

    public componentWillUnmount(): void {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    public handleRetry(): void {
        this.setState({ hasError: false });
    }

    public handlePointerDown(): void {
        const { onClose } = this.props;

        onClose?.();
    }

    public handleKeyDown(e: Event): void {
        const event = e as unknown as KeyboardEvent;

        if (event.key === "Escape") {
            const { onClose } = this.props;

            onClose?.();
        }
    }

    public render(): JSX.Element {
        const { hasError } = this.state;

        const {
            children,
            parent,
            width,
            transitionStatus,
            intl,
            onClose,
            enableTopClosure,
            innerRef,
            padding,
            ...otherProps
        } = this.props;

        const hasClosure = typeof onClose === "function";

        const content = (
            <StyledBackground
                {...otherProps}
                $transitionStatus={transitionStatus}
                onPointerDown={hasClosure ? this.handlePointerDown : undefined}
            >
                <HiddenBodyOverflow />
                <StyledDialog $padding={padding} $width={width} ref={innerRef}>
                    {enableTopClosure ? <TopClosure onClick={onClose} /> : null}
                    {hasError ? (
                        <Error
                            footer={
                                <>
                                    {typeof onClose === "function" && (
                                        <Button
                                            kind="secondary"
                                            onClick={onClose}
                                            size="medium"
                                        >
                                            {intl.formatMessage({
                                                id: "components.modals.error.cancel",
                                                description:
                                                    "Error boundary cancel button text",
                                                defaultMessage: "Cancel",
                                            })}
                                        </Button>
                                    )}
                                    <Button
                                        kind="primary"
                                        onClick={this.handleRetry}
                                        size="medium"
                                    >
                                        {intl.formatMessage({
                                            id: "components.modals.error.retry",
                                            description:
                                                "Error boundary try again button text",
                                            defaultMessage: "Try again",
                                        })}
                                    </Button>
                                </>
                            }
                            title="Oops.."
                        >
                            {intl.formatMessage({
                                id: "components.modals.error.message",
                                description: "Error boundary message text",
                                defaultMessage: "Sorry.. there was an error",
                            })}
                        </Error>
                    ) : (
                        children
                    )}
                </StyledDialog>
            </StyledBackground>
        );
        const portal =
            typeof parent === "string"
                ? document.getElementById(parent)
                : parent;

        return portal ? createPortal(content, portal) : content;
    }
}

const ModalWithIntl = injectIntl<"intl", ModalProps>(ModalErrorBoundary);

export const Modal = forwardRef<HTMLDivElement, Omit<ModalProps, "intl">>(
    (props, ref) => <ModalWithIntl innerRef={ref} {...props} />,
);

Modal.displayName = "Modal";
