import dayjs, { extend } from "dayjs";
import weekday from "dayjs/plugin/weekday";

extend(weekday);

export const getCurrentWeekFirstDayDate = (): Date => {
    // The first day of the week is Monday (2nd day of the week when locale is "en")
    return dayjs().weekday(1).startOf("day").toDate();
};

export const getCurrentMonthFirstDayDate = (): Date => {
    return dayjs().startOf("month").toDate();
};

export const getDaysBetween = (from: Date, to: Date): number => {
    return dayjs(to).diff(from, "day");
};

export const getDaysUntilNow = (to: Date): number => {
    const now = dayjs().startOf("day");
    const toDate = dayjs(to).startOf("day");

    return getDaysBetween(now.toDate(), toDate.toDate());
};

export const getNextFirstDayOfAMonth = (): Date => {
    const today = new Date();

    return today.getDate() === 1
        ? today
        : new Date(today.getFullYear(), today.getMonth() + 1, 1);
};
