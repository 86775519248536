import styled, { css } from "styled-components";

const fontWeightMixin = css<{ $fontWeight?: number }>`
    ${({ $fontWeight }) => ($fontWeight ? `font-weight: ${$fontWeight};` : "")}
`;

const styleH0 = css`
    font-size: var(--size-h0);
    font-weight: 700;
    line-height: var(--height-h0);
    ${fontWeightMixin}
`;

const H0 = styled.h1`
    ${styleH0}
`;
const styleH1 = css`
    font-size: var(--size-h1);
    line-height: var(--height-h1);
    ${fontWeightMixin}
`;

const H1 = styled.h1`
    ${styleH1}
`;

const styleH2 = css`
    font-size: var(--size-h2);
    line-height: var(--height-h2);
    ${fontWeightMixin}
`;

const H2 = styled.h2`
    ${styleH2}
`;

const styleH3 = css`
    font-size: var(--size-h3);
    line-height: var(--height-h3);
    ${fontWeightMixin}
`;

const H3 = styled.h3`
    ${styleH3}
`;

const styleBodyL = css`
    font-size: var(--size-body-l);
    line-height: var(--height-body-l);
    ${fontWeightMixin}
`;

const BodyL = styled.span`
    ${styleBodyL}
`;

const styleBody = css`
    font-size: var(--size-body);
    line-height: var(--height-body);
    ${fontWeightMixin}
`;

const Body = styled.span`
    ${styleBody}
`;

const styleCaption = css`
    font-size: var(--size-caption);
    line-height: var(--height-caption);
    ${fontWeightMixin}
`;

const Caption = styled.span`
    ${styleCaption}
`;

const styleBold = css`
    font-weight: 500;
    ${fontWeightMixin}
`;

const Bold = styled.span`
    ${styleBold}
`;

export const Text = {
    H0,
    H1,
    H2,
    H3,
    BodyL,
    Body,
    Caption,
    Bold,
    style: {
        H1: styleH1,
        H2: styleH2,
        H3: styleH3,
        BodyL: styleBodyL,
        Body: styleBody,
        Caption: styleCaption,
        Bold: styleBold,
    },
};
