import { type ReactNode, type JSX } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { FeatureGating } from "@/Support/Components/FeatureGating";
import { useMe } from "@/Support/Requests/useMe";

export const ProtectedRoute = ({
    children,
    notGated,
}: {
    readonly children: ReactNode;
    readonly notGated?: boolean;
}): JSX.Element | ReactNode => {
    const query = useMe();

    const location = useLocation();

    if (!query.isSuccess) {
        return (
            <Navigate
                to={{
                    pathname: "/login",
                    search:
                        location.pathname !== "/" || location.search
                            ? `redirect_domain=w2&redirect_path=${encodeURIComponent(
                                  `${location.pathname}${location.search}`,
                              )}`
                            : undefined,
                }}
            />
        );
    }

    if (notGated) {
        return children;
    }

    return <FeatureGating>{children}</FeatureGating>;
};
