import { graphql } from "@/__codegen__";

import { type UseQueryOptions, useQuery } from "@/Support/Hooks/useQuery";

import {
    type EditorBillingQuery,
    type EditorBillingQueryVariables,
    namedOperations,
} from "@/__codegen__/graphql";

import { type RequestError, graphQLClient } from "./graphQLClient";
import { ReactQueryKeys } from "./reactQueryKeys";

export type EditorBillingError = RequestError;

/** Gets billing information from a workspace for EDITOR as they don't have access to whole billing data as OWNER or ADMINS do */
export const useEditorBilling = (
    variables: EditorBillingQueryVariables,
    options?: UseQueryOptions<
        EditorBillingQuery["pageviewInformation"],
        EditorBillingError
    >,
) =>
    useQuery({
        queryKey: [
            ReactQueryKeys.BILLING,
            namedOperations.Query.EditorBilling,
            variables,
        ],
        queryFn: async ({ signal }) => {
            const { pageviewInformation } = await graphQLClient.request({
                document: graphql(`
                    query EditorBilling($workspaceId: Int!) {
                        pageviewInformation(workspaceId: $workspaceId) {
                            current_pageviews
                            plan_pageviews
                            expires_at
                            is_expired
                            is_trial
                            trial_duration
                            extra_pageviews_enabled
                            expires_at
                            plan_price_month
                            plan_name
                        }
                    }
                `),
                variables,
                signal,
            });

            return pageviewInformation;
        },
        ...options,
    });
