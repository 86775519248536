import {
    type HTMLAttributes,
    type ReactNode,
    type JSX,
    forwardRef,
} from "react";
import styled, {
    type FlattenSimpleInterpolation,
    css,
} from "styled-components";

type Shape = "round" | "square";
type Kind = "light" | "solid";
type Size = "medium" | "small";
type Color = "green" | "neutral" | "primary" | "purple" | "red" | "yellow";

const BadgeBase = styled.div<{
    $size: Size;
    $shape: Shape;
    $kind: Kind;
}>`
    font-weight: 500;
    width: fit-content;
    ${({ $size }): FlattenSimpleInterpolation =>
        $size === "small"
            ? css`
                  font-size: var(--size-caption);
                  line-height: var(--height-caption);
              `
            : css`
                  font-size: var(--size-body);
                  line-height: var(--height-body);
              `}
    ${({ $shape, $size }): FlattenSimpleInterpolation =>
        $shape === "square"
            ? css`
                  border-radius: 4px;
                  ${$size === "small"
                      ? css`
                            padding: 0 4px;
                        `
                      : css`
                            padding: 6px 8px;
                        `}
              `
            : css`
                  border-radius: 14px;
                  ${$size === "small"
                      ? css`
                            padding: 0 4px;
                        `
                      : css`
                            padding: 6px 12px;
                        `}
              `}
`;

const BadgePrimary = styled(BadgeBase)`
    ${({ $kind }): FlattenSimpleInterpolation =>
        $kind === "light"
            ? css`
                  background-color: var(--color-blue-100);
                  color: var(--color-blue-600);
              `
            : css`
                  background-color: var(--color-blue-500);
                  color: var(--color-white);
              `}
`;

const BadgeGreen = styled(BadgeBase)`
    ${({ $kind }): FlattenSimpleInterpolation =>
        $kind === "light"
            ? css`
                  background-color: var(--color-success-100);
                  color: var(--color-success-500);
              `
            : css`
                  background-color: var(--color-success-500);
                  color: var(--color-white);
              `}
`;

const BadgeRed = styled(BadgeBase)`
    ${({ $kind }): FlattenSimpleInterpolation =>
        $kind === "light"
            ? css`
                  background-color: var(--color-destructive-50);
                  color: var(--color-destructive-500);
              `
            : css`
                  background-color: var(--color-destructive-500);
                  color: var(--color-white);
              `}
`;

const BadgeYellow = styled(BadgeBase)`
    ${({ $kind }): FlattenSimpleInterpolation =>
        $kind === "light"
            ? css`
                  background-color: var(--color-warning-100);
                  color: var(--color-warning-600);
              `
            : css`
                  background-color: var(--color-warning-500);
                  color: var(--color-white);
              `}
`;

const BadgePurple = styled(BadgeBase)`
    ${({ $kind }): FlattenSimpleInterpolation =>
        $kind === "light"
            ? css`
                  background-color: var(--color-violet-100);
                  color: var(--color-violet-600);
              `
            : css`
                  background-color: var(--color-violet-500);
                  color: var(--color-white);
              `}
`;

const BadgeNeutral = styled(BadgeBase)`
    ${({ $kind }): FlattenSimpleInterpolation =>
        $kind === "light"
            ? css`
                  background-color: var(--color-neutral-200);
                  color: var(--color-neutral-600);
              `
            : css`
                  background-color: var(--color-neutral-600);
                  color: var(--color-white);
              `}
`;

const BadgesColors: Record<Color, typeof BadgeNeutral> = {
    primary: BadgePrimary,
    green: BadgeGreen,
    red: BadgeRed,
    yellow: BadgeYellow,
    purple: BadgePurple,
    neutral: BadgeNeutral,
};

type BadgeProps = HTMLAttributes<HTMLDivElement> & {
    readonly shape: Shape;
    readonly size: Size;
    readonly kind: Kind;
    readonly color: Color;
    readonly children: ReactNode;
};

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
    (
        { color, kind, shape, size, children, ...otherProps },
        ref,
    ): JSX.Element => {
        const Component = BadgesColors[color];

        return (
            <Component
                $kind={kind}
                $shape={shape}
                $size={size}
                ref={ref}
                {...otherProps}
            >
                {children}
            </Component>
        );
    },
);

Badge.displayName = "Badge";
